import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

import valerian from "../images/valerian-1.svg"

const ProductsPage = () => (
  <Layout>
    <PageTitle title="Products" page="PageTitleProducts" />
    <Seo title="Products"
         description="We build, modify and extend custom software and enterprise solutions such as reporting, workflow, data management, and web." />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>In addition to providing technology services, we also develop and launch our own products periodically. In some cases they may be open source 
                projects and/or components, in other cases they are completely separate products that we launch for our customers. Valerian is a great example of 
                a need of some of our customers that we turned into a Software-as-a-Service (SaaS) offering. 
            </p>
        </div>
        <div className="home-valerian">
            <div className="val-left">
                <h2>Now Available: Valerian</h2>
                <h3>Simplifying E-commerce</h3>
                <p>Valerian consolidates e-commerce data from Shopify, Square, ERPs, CRMs and custom systems.</p>
                <a href="https://www.valeriansolutions.com?utm_source=newvertical&utm_medium=website&utm_campaign=nowavailable" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
            <div className="val-right">
                <img src={valerian} alt="Valerian integrates systems" />
            </div>
        </div>
        
    </div>        
  </Layout>
)

export default ProductsPage
